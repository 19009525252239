/**
 * Constructor
 */

type Config = {
  origin: string
  launchpad: string
  eliteNft: string
  a8Ultimate: string
}

/**
 * Location config is used for SEO
 */
const configs: Config = {
  origin: process.env.NEXT_PUBLIC_ORIGIN as string,
  launchpad: process.env.NEXT_PUBLIC_LAUNCHPAD_URL as string,
  eliteNft: process.env.NEXT_PUBLIC_ELITE_NFT_URL as string,
  a8Ultimate: process.env.NEXT_PUBLIC_A8_ULTIMATE_URL as string,
}

/**
 * Module exports
 */
export default configs
